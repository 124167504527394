import { getFrameworkVars, getState } from '@sparky/framework';
import { SPARKY_NAV3_ROUTES_EXTPOINT } from '@sparky/framework/extpoints';
import { nav3Paths } from './paths';
import { isFeatureEnabled } from './utils';
export default {
    point: SPARKY_NAV3_ROUTES_EXTPOINT,
    configs: [
        {
            id: 'sase.dem_app.routes.settings',
            config: {
                stateLoaders: ['auth', 'adem_main'],
                path: nav3Paths.Setting,
                contentClassName: 'dem-settings',
                component: function () {
                    return import('../saseViews/SettingsView/SettingsViewWrapper');
                },
                isAllowed: function () {
                    var _a, _b, _c;
                    return isFeatureEnabled('panw_sase:settings_system_application', true) ||
                        ((_c = (_b = (_a = getState()) === null || _a === void 0 ? void 0 : _a.adem_main) === null || _b === void 0 ? void 0 : _b.setUpResponse) === null || _c === void 0 ? void 0 : _c.isSettingsVisible);
                },
                exact: true,
            },
        },
        {
            id: 'sase.dem_app.routes.palocations',
            config: {
                path: '/operational-insights/prisma-access-locations',
                contentClassName: 'monitor-palocations',
                component: function () {
                    return import('../saseViews/PrismaLocationView/PrismaLocationViewWrapper');
                },
                isAllowed: function () { return isFeatureEnabled('panw_sase:pa_location', true); },
                exact: false,
            },
        },
        {
            id: 'sase.dem_app.routes.domain-test-form',
            config: {
                path: nav3Paths.NewAppTest,
                component: function () {
                    return import('../saseViews/AppTestFormView/ExposedAppTestFormView');
                },
                exact: true,
            },
        },
        {
            id: 'sase.dem_app.routes.application-domain-details',
            config: {
                path: nav3Paths.RumAppDetails,
                component: function () {
                    return import('../saseViews/AppDetailView/ExposedAppDetailView');
                },
                exact: false,
            },
        },
        {
            id: 'sase.dem_app.routes.application-experience',
            config: {
                path: '/operational-insights/application-experience',
                component: function () {
                    return import('../saseViews/ApplicationExperienceView/ExposedApplicationExperienceView');
                },
                isAllowed: function () {
                    return isFeatureEnabled('panw_sase:application_experience', true);
                },
                exact: false,
            },
        },
        // insights
        {
            id: 'sase.dem_app.routes.security-insights-app-details',
            config: {
                path: nav3Paths.AppDetails,
                component: function () { return import('../saseViews/appsView/AppDetailsViewWrapper'); },
            },
            exact: false,
        },
        // todo: move the route
        {
            id: 'sase.dem_app.routes.security-insights-app-details',
            config: {
                path: nav3Paths.ZoomRedirect,
                component: function () { return import('../saseViews/AppDetailView/AppDetailsRoutes'); },
            },
            exact: false,
        },
        // todo: move the route
        {
            id: 'sase.dem_app.routes.security-insights-app-details',
            config: {
                path: nav3Paths.MsTeamsRedirect,
                component: function () { return import('../saseViews/AppDetailView/AppDetailsRoutes'); },
            },
            exact: false,
        },
        {
            id: 'sase.dem_app.routes.security-insights-user-details',
            config: {
                path: nav3Paths.UserDetails,
                component: function () {
                    return import('../saseViews/UsersView/UsersViewDetailsWrapper');
                },
                exact: false,
            },
        },
        {
            id: 'sase.dem_app.routes.security-insights-user-self-serve',
            config: {
                path: nav3Paths.UserSelfServe,
                component: function () {
                    return import('../saseViews/UsersView/UsersViewDetailsWrapper');
                },
                exact: false,
            },
        },
        //ngfw
        {
            id: 'sase.dem_app.routes.ngfw-details-experience',
            config: {
                path: nav3Paths.NgfwSiteDetails,
                component: function () {
                    return import('../saseViews/NgfwSitesView/NgfwSitesViewWrapper');
                },
                exact: true,
            },
        },
        //pdf
        {
            id: 'sase.dem_app.routes.adem-pdf-app-activity-view',
            config: {
                path: '/dem/dem-pdf-views/app-detail',
                component: function () {
                    return import('../sasePdfViews/AppActivityPdfView/ExposedAppActivityPdfView');
                },
                exact: false,
                isAllowed: function () { var _a; return !!((_a = getFrameworkVars()) === null || _a === void 0 ? void 0 : _a.pdf_mode); },
            },
        },
        {
            id: 'sase.dem_app.routes.adem-pdf-user-activity-view',
            config: {
                path: '/dem/dem-pdf-views/user-detail',
                component: function () {
                    return import('../sasePdfViews/UserDetailsPdfView/UserDetailsPdfView');
                },
                exact: false,
                isAllowed: function () { var _a; return !!((_a = getFrameworkVars()) === null || _a === void 0 ? void 0 : _a.pdf_mode); },
            },
        },
    ],
};
