import { SPARKY_NAV3_REDIRECTS_EXTPOINT, } from '@sparky/framework/extpoints';
// import { legacySaseViewRoutes } from '../saseViews/saseView.routes';
// type ObjectKeysAsList<T> = (keyof T)[];
// temp - use this to generate the redirects,
// comment out here because we need to avoid import saseViews/saseView.routes as the loader has hard limit 300000 bytes
// const redirectKeys: ObjectKeysAsList<typeof legacySaseViewRoutes> = [
//   'RumAppTestForm',
//   'RumAppDomains',
//   'RumDashboard',
//   'RumAppDetails',
//   'ZoomRedirect',
//   'MsTeamsRedirect',
//   'AppDetails',
//   'AppDetailsExperience',
//   'AppDetailsActivity',
//   'UserDetails',
//   'UserDetailsThreats',
//   'UserDetailsExperience',
//   'UserDetailsConnectivity',
//   'UserSelfServe',
//   'Locations',
//   'LocationDetails',
//   'LocationDetailsConnectivity',
//   'LocationDetailsExperience',
//   'NgfwSites',
//   'NgfwSiteDetails',
// ];
// console.log(
//   '@@@',
//   redirectKeys.map((key) => ({
//     id: `sase.dem_app.nav.redirects.${key}`,
//     config: {
//       path: legacySaseViewRoutes[key],
//       //@ts-expect-error fix type
//       redirectTo: nav3Paths[key],
//       preserveSearch: true,
//     },
//   }))
// );
export default {
    point: SPARKY_NAV3_REDIRECTS_EXTPOINT,
    // generate from above code
    configs: [
        {
            id: 'sase.dem_app.nav.redirects.RumAppTestForm',
            config: {
                path: '/dem/dem-rum-views/new-test',
                redirectTo: '/dem/dem-rum-views/new-test',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumAppDomains',
            config: {
                path: '/dem/dem-rum-views/application-experience/app-domains',
                redirectTo: '/operational-insights/application-experience/app-domains',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumDashboard',
            config: {
                path: '/dem/dem-rum-views/application-experience/your-org',
                redirectTo: '/operational-insights/application-experience/your-org',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumAppDetails',
            config: {
                path: '/dem/dem-rum-views/app-detail-dashboard',
                redirectTo: '/operational-insights/application-experience/domain-details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.ZoomRedirect',
            config: {
                path: '/insights/activity_insights_app/apps/dem-zoom-redirect',
                redirectTo: '/operational-insights/application-experience/dem-zoom-redirect',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.MsTeamsRedirect',
            config: {
                path: '/insights/activity_insights_app/apps/dem-msteams-redirect',
                redirectTo: '/operational-insights/application-experience/dem-msteams-redirect',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetails',
            config: {
                path: '/insights/activity_insights_app/apps/details',
                redirectTo: '/security-insights/activity_insights/apps/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetailsExperience',
            config: {
                path: '/insights/activity_insights_app/apps/details/experience',
                redirectTo: '/security-insights/activity_insights/apps/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetailsActivity',
            config: {
                path: '/insights/activity_insights_app/apps/details/activity',
                redirectTo: '/security-insights/activity_insights/apps/details/activity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetails',
            config: {
                path: '/insights/activity_insights_user/users/details',
                redirectTo: '/security-insights/activity_insights/users/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsThreats',
            config: {
                path: '/insights/activity_insights_user/users/details/threats',
                redirectTo: '/security-insights/activity_insights/users/details/threats',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsExperience',
            config: {
                path: '/insights/activity_insights_user/users/details/experience',
                redirectTo: '/security-insights/activity_insights/users/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsConnectivity',
            config: {
                path: '/insights/activity_insights_user/users/details/connectivity',
                redirectTo: '/security-insights/activity_insights/users/details/connectivity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserSelfServe',
            config: {
                path: '/insights/activity_insights_user/users/self-serve',
                redirectTo: '/security-insights/activity_insights/users/self-serve',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.Locations',
            config: {
                path: '/monitor/palocations',
                redirectTo: '/operational-insights/prisma-access-locations',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetails',
            config: {
                path: '/monitor/palocations/details',
                redirectTo: '/operational-insights/prisma-access-locations/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetailsConnectivity',
            config: {
                path: '/monitor/palocations/details/connectivity',
                redirectTo: '/operational-insights/prisma-access-locations/details/connectivity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetailsExperience',
            config: {
                path: '/monitor/palocations/details/experience',
                redirectTo: '/operational-insights/prisma-access-locations/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.NgfwSites',
            config: {
                path: '/monitor/branch-sites/ngfwSites',
                redirectTo: '/operational-insights/sase-branch-sites/ngfwSites',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.NgfwSiteDetails',
            config: {
                path: '/monitor/branch-sites/ngfwSites/details/experience',
                redirectTo: '/operational-insights/sase-branch-sites/ngfwSites/details/experience',
                preserveSearch: true,
            },
        },
    ],
};
